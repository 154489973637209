import { ThemeConfig } from "antd";

import colors from "./colors";
import seedtime from "./seedtime";

const theme: ThemeConfig = {
  components: {
    Breadcrumb: {
      linkHoverColor: colors.linkHover,
      linkColor: colors.primary
    },
    Checkbox: {
      colorBgContainer: colors["page-background"],
      fontSize: 13,
      marginXS: 0
    },
    Dropdown: {
      borderRadiusLG: 4,
      controlPaddingHorizontal: 8,
      fontSize: 13,
      marginXXS: 0,
      paddingXXS: 0
    },
    Popover: {
      paddingSM: 8,
      fontSize: 13
    },
    Tabs: {
      horizontalItemPadding: "12px 30px",
      itemSelectedColor: "#332b25",
      horizontalMargin: "0 32px 32px 32px"
    },
    Tooltip: {
      fontSize: 13
    }
  },
  token: {
    colorLink: colors.primary,
    colorPrimary: colors.primary,
    colorSuccessText: colors.primary,
    colorTextSecondary: colors["mid-grey"],
    fontFamily: seedtime["text-font"],
    fontSize: 16
  }
};

export default theme;
